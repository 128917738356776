@import '../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~ngx-toastr/toastr';

html {
  scroll-behavior: smooth;
}

// Material Dialog Styles
.normal-modal .mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
  max-height: 90vh !important;
}

.full-screen-modal .mat-dialog-container {
  padding: 0 !important;
  border-radius: 0px !important;
  width: 100%;
  height: 100%;
}

.public-container {
  @apply container;
  @screen xl {
    max-width: 1152px;
  }
}

// Material expansion panel style
.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
  background: #f3f4f6 !important;
}

.dashbord-section-text {
  @screen md {
    max-width: 700px;
  }
}
